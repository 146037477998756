<template>
  <!-- Modal -->
  <ModalLayout :ref="modalRef" modal-id="donorDetailsModal"
    :loading="isSavableStateLoading" @close="closeModal()">
    <template #modal-header>
      <template v-if="donor_details && recipient_details">
        <h5  class="modal-title">
          {{ `Donor: ${donor_details.donor.code} | Match: ${match.code}-${match.organ.name} | TC: ${match.transplant_center.code} ` }}
        </h5>
      </template>
      <h5 v-else>
        Loading match details
      </h5>
    </template>
    <template v-if="donor_details && recipient_details" #modal-sub-header>
      <div  v-if="match['on_external_dashboard?']">
        External Link: <a target="_blank" :href="externalLink"  @click.prevent="copyLink">
          Donor Link <font-awesome-icon :class="{ 'text-success': copyIcon === 'fa-circle-check' }" :icon="['fas', copyIcon]"/>
        </a>
      </div>
    </template>
    <template #modal-body>
      <div v-if="donor_details && recipient_details" class="row">
        <div class="col-sm-12">
          <DonorDetails :details="donor_details" :recipient_details="recipient_details" :match="match" />
        </div>
      </div>
    </template>
  </ModalLayout>
</template>

<script lang="js">
import DonorDetails from "@/src/components/buckeye-coordinator-area/DonorDetails.vue";
import ModalLayout from "@/src/components/shared/ModalLayout.vue";
import {savableStateMixin} from "@/src/mixins/savableStateMixin";
import {modalActionsMixin} from "@/src/mixins/modalActionsMixin";
import {APIRoute, EP} from "@/src/endpoints";
import beApiClient from "@/src/be-api-client";

export default {
  components: {
    DonorDetails,
    ModalLayout
  },
  mixins: [savableStateMixin, modalActionsMixin],
  props: {
    matchId: { required: true, type: String },
    donorId: { required: true, type: String }
  },
  emits: ['close'],
  data() {
    return {
      modalRef: 'donor-details-modal',
      copyIcon: 'fa-copy',
      match: null,
      donor_details: null,
      recipient_details: null
    }
  },
  watch: {
    matchId(newRow, _oldRow) {
      this.getModalData();
      this.disableSaving = false;
    },
    donorId(newRow, _oldRow) {
      this.getModalData();
      this.disableSaving = false;
    }
  },
  computed: {
    externalLink() {
      if(!this.matchId || !this.donorId) {
        return '#'
      }
      return this.$router.resolve({ 
        name: 'external-donor-details',
        params: { 
          id: this.matchId,
          donor_id: this.donorId
        }, 
        query: {
          organ: this.match.organ.organ_group.name,
          tc: this.match.transplant_center.code
        } 
      }).href;
    },
  },
  mounted() {
    this.getModalData();
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(`${window.location.host}${this.externalLink}`);
      this.copyIcon = 'fa-circle-check';

      let _vm = this;
      setTimeout(() => {
        _vm.copyIcon = 'fa-copy';
      }, 3000);
    },
    getModalData() {
      if((!this.matchId || !this.donorId) || this.isSavableStateLoading) return;

      this.setSavableStateLoading();
      this.resetDonorDetails();
      const match_details_ep = APIRoute(EP.matches.show, { id: this.matchId });
      const recipients_ep = APIRoute(EP.matches.recipients.index, { match_id: this.matchId });
      const donor_details_ep = APIRoute(EP.donors.show, { id: this.donorId });

      Promise.all([
        beApiClient.get(recipients_ep, { params: { limit: 10 }}),
        beApiClient.get(donor_details_ep),
        beApiClient.get(match_details_ep)
      ]).then((response) => {
        const recipient_data = response[0].data;
        const donor_data = response[1].data;
        const match_data = response[2].data
        this.recipient_details = recipient_data;
        this.donor_details = donor_data;
        this.match = match_data;
      }).finally(() => {
        this.setSavableStateIdle();
      })
    },
    resetDonorDetails() {
      this.match = null;
      this.donor_details = null;
      this.recipient_details = null;
    },
    closeModal(){
      this.$emit("close");
    }
  }
}
</script>
