import DashboardArea from "@/src/components/buckeye-coordinator-area/DashboardArea.vue";
import PluginDashboard from "@/src/components/buckeye-coordinator-area/dashboards/PluginDashboard.vue";
import ManualDashboard from "@/src/components/buckeye-coordinator-area/dashboards/ManualDashboard.vue";
import UnfinishedBusinessDashboard from "@/src/components/buckeye-coordinator-area/dashboards/UnfinishedBusinessDashboard.vue";
import ManageOffer from "@/src/components/buckeye-coordinator-area/manual_offers/ManageOffer.vue";
import PluginArea from "@/src/components/buckeye-coordinator-area/plugin_templates/PluginArea.vue";
import GenerateInstance from "@/src/components/buckeye-coordinator-area/plugin_templates/GenerateInstance.vue";
import EditInstance from "@/src/components/buckeye-coordinator-area/plugin_templates/EditInstance.vue";
import MatchInstances from "@/src/components/buckeye-coordinator-area/plugin_templates/MatchInstances.vue";
import {PermissionNames} from "@/src/enums";

export const internalRoutes = [
  {
    path: 'internal',
    children: [
      {
        path: 'dashboards',
        children: [
          {
            path: '',
            name: 'internal-dashboards-root',
            component: DashboardArea,
            meta: {
              permissions: ['area_internal']
            }
          },
          {
            path: 'manual',
            name: 'internal-dashboard-manual',
            component: ManualDashboard,
            meta: {
              permissions: []
            }
          },
          {
            path: 'plugin',
            name: 'internal-dashboard-plugin',
            component: PluginDashboard,
            meta: {
              permissions: ['internal_dashboard_plugin']
            }
          },
          {
            path: 'unfinished-business',
            name: 'internal-dashboard-unfinished-business',
            component: UnfinishedBusinessDashboard,
            meta: {
              permission: ['internal_dashboard_unfinished_business']
            }
          }
        ]
      },
      {
        path: 'offers',
        name: 'manage-offer',
        component: ManageOffer,
        meta: {
          permissions: ['area_internal']
        }
      },
      {
        path: 'instances',
        component: PluginArea,
        children: [
          {
            path: 'generate-instance/:donor',
            name: 'generate-instance',
            components: {
              default: GenerateInstance,
            },
            meta: {
              permissions: ['area_internal']
            }
          },
          {
            path: 'generate-instance/:donor/:match',
            name: 'generate-instance',
            components: {
              default: GenerateInstance,
            },
            meta: {
              permissions: ['area_internal']
            } 
          },
          {
            path: 'generate-instance/:donor/:match/:txc',
            name: 'generate-instance',
            components: {
              default: GenerateInstance,
            },
            meta: {
              permissions: ['area_internal']
            }
          },
          {
            path: 'generate-instance/:donor/:match/:txc/:organGroup',
            name: 'generate-instance',
            components: {
              default: GenerateInstance,
            },
            meta: {
              permissions: ['area_internal']
            },
            props: true
          },
          {
            path: 'edit-instance/:donor/:match/:txc/:organGroup/:instanceId',
            name: 'edit-instance',
            components: {
              default: EditInstance,
            },
            meta: {
              permissions: ['area_internal']
            },
            props: true
          },
          {
            path: 'match-instances',
            name: 'match-instances',
            components: {
              default: MatchInstances,
            },
            meta: {
              permissions: ['area_internal']
            },
            props: true
          },
        ]
      }
    ]
  }
]
