import AdminArea from "@/src/components/admin-area/AdminArea.vue";
import AdminDashboard from "@/src/components/admin-area/AdminDashboard.vue";
import RoleIndex from "@/src/components/admin-area/roles/RoleIndex.vue";
import MembershipCategoryIndex from "@/src/components/admin-area/memberships-categories/MembershipCategoryIndex.vue";
import MembershipCategoryEdit from "@/src/components/admin-area/memberships-categories/MembershipCategoryEdit.vue";
import TroubleshootDonors from "@/src/components/admin-area/troubleshoot/donors/TroubleshootDonors.vue";
import ManageReports from '@/src/components/admin-area/reports/ManageReports.vue';
import ReportSet from "@/src/components/admin-area/reports/MonthlyTxCReportSet.vue";
import TemplateCategoryEdit from '@/src/components/admin-area/templates/TemplateCategoryEdit.vue';
import TemplateCategoryIndex from '@/src/components/admin-area/templates/TemplateCategoryIndex.vue';
import TemplateEdit from '@/src/components/admin-area/templates/TemplateEdit.vue';
import TemplateIndex from '@/src/components/admin-area/templates/TemplateIndex.vue';
import UserIndex from "@/src/components/admin-area/users/UserIndex.vue";
import UserEdit from "@/src/components/admin-area/users/UserEdit.vue";
import UserEditTeams from "@/src/components/admin-area/users/UserEditTeams.vue";
import UserEditAdvanced from "@/src/components/admin-area/users/UserEditAdvanced.vue";
import TeamIndex from "@/src/components/admin-area/teams/TeamIndex.vue";
import TeamEdit from '@/src/components/admin-area/teams/TeamEdit.vue';
import TeamEditManage from '@/src/components/admin-area/teams/TeamEditManage.vue';
import TeamEditAdvanced from '@/src/components/admin-area/teams/TeamEditAdvanced.vue';
import ManageGroupIndex from '@/src/components/admin-area/teams/groups/ManageGroupIndex.vue';
import ManageGroupEdit from '@/src/components/admin-area/teams/groups/ManageGroupEdit.vue';
import ARORulesIndex from "@/src/components/admin-area/aro-rules/ARORulesIndex.vue";
import ARORulesEdit from '@/src/components/admin-area/aro-rules/ARORulesEdit.vue';
import RobocallReductionsEdit from '@/src/components/admin-area/application-settings/RobocallReductionsEdit.vue';
import { useAppCoreStore } from "@/src/stores/app-core";
import ProcedureNotesIndex from "@/src/components/admin-area/procedure-notes/ProcedureNotesIndex.vue";
import ProcedureNotesEdit from '@/src/components/admin-area/procedure-notes/ProcedureNotesEdit.vue';
import ImportUsers from "@/src/components/admin-area/import-users/ImportUsers.vue";
import RoleEdit from "@/src/components/admin-area/roles/RoleEdit.vue";
import { adminIntegrationRoutes } from "./admin/integration-routes";
import MonthlyBusinessReportSet from "../components/admin-area/reports/MonthlyBusinessReportSet.vue";
import {PermissionNames} from "@/src/enums";

export function setAdminBreadCrumb() {
  const appStore = useAppCoreStore();
  appStore.setAppBreadcrumbs([
    {
      text: 'Admin Area'
    }
  ]);
}

export const adminRoutes = [
  {
    path: 'admin',
    component: AdminArea,
    beforeEnter: [ setAdminBreadCrumb ],
    children: [
      {
        path: '',
        name: 'admin_root',
        component: AdminDashboard,
        beforeEnter: [ setAdminBreadCrumb ],
        meta: {
          permissions: [PermissionNames.AREA_ADMIN]
        }
      },
      {
        path: 'dashboard',
        name: 'admin_dashboard',
        component: AdminDashboard,
        beforeEnter: [ setAdminBreadCrumb ],
        meta: {
          permissions: [PermissionNames.AREA_ADMIN]
        }
      },
      {
        path: 'teams',
        children: [
          {
            path: '',
            name: PermissionNames.ADMIN_TEAMS,
            component: TeamIndex,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_TEAMS]
            }
          },
          {
            path: 'new',
            name: 'admin_teams_new',
            component: TeamEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_TEAMS],
              permissionsKey: 'create'
            }
          },
          {
            path: ':id',
            name: 'admin_teams_edit',
            component: TeamEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_TEAMS],
              permissionsKey: 'write'
            }
          },
          {
            path: ':id/manage',
            name: 'admin_teams_edit_manage',
            component: TeamEditManage,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_TEAMS],
              permissionsKey: 'write'
              
            }
          },
          {
            path: ':id/advanced',
            name: 'admin_teams_edit_advanced',
            component: TeamEditAdvanced,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_TEAMS],
              permissionsKey: 'write'
            }
          },
          {
            path: ':id/groups',
            name: 'admin_teams_edit_groups_index',
            component: ManageGroupIndex,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_TEAMS]
            }
          },
          {
            path: ':id/groups/new',
            name: 'admin_teams_edit_groups_new',
            component: ManageGroupEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_TEAMS],
              permissionsKey: 'create'
            }
          },
          {
            path: ':id/groups/:group_id',
            name: 'admin_teams_edit_groups_edit',
            component: ManageGroupEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_TEAMS],
              permissionsKey: 'write'
            }
          }
        ]
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            name: 'admin_users',
            component: UserIndex,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_USERS]
            }
          },
          {
            path: 'new',
            name: 'admin_users_new',
            component: UserEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_USERS],
              permissionsKey: 'create'
            }
          },
          {
            path: ':id',
            name: 'admin_users_edit',
            component: UserEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_USERS],
              permissionsKey: 'write'
            }
          },
          {
            path: ':id/teams',
            name: 'admin_users_edit_teams',
            component: UserEditTeams,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_USERS],
              permissionsKey: 'write'
            }
          },
          {
            path: ':id/advanced',
            name: 'admin_users_edit_advanced',
            component: UserEditAdvanced,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_USERS],
              permissionsKey: 'write'
            }
          },
        ],
      },
      {
        path: 'import_users',
        name: 'admin_mass_import_users',
        component: ImportUsers,
        beforeEnter: [ setAdminBreadCrumb ],
        meta: {
          permissions: [PermissionNames.ADMIN_USERS_MASS_IMPORT]
        }
      },
      {
        path: 'roles',
        children: [
          {
            path: '',
            name: 'admin_roles',
            component: RoleIndex,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_ROLES]
            }
          },
          {
            path: 'new',
            name: 'admin_roles_new',
            component: RoleEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_ROLES],
              permissionsKey: 'create'
            }
          },
          {
            path: ':id',
            name: 'admin_roles_edit',
            component: RoleEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_ROLES],
              permisssionsKey: 'write'
            }
          }
        ]
      },
      {
        path: 'membership-categories',
        children: [
          {
            path: '',
            name: 'membership_categories',
            component: MembershipCategoryIndex,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_TEAMS]
            }
          },
          {
            path: 'new',
            name: 'membership_categories_new',
            component: MembershipCategoryEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_MEMBERSHIP_CATEGORIES],
              permissionsKey: 'create'
            }
          },
          {
            path: ':id',
            name: 'membership_categories_edit',
            component: MembershipCategoryEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_MEMBERSHIP_CATEGORIES],
              permissionsKey: 'write'
            }
          },
        ],
      },
      {
        path: 'template-categories',
        children: [
          {
            path: '',
            name: 'admin_communication_template_categories',
            component: TemplateCategoryIndex,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_MEMBERSHIP_CATEGORIES]
            }
          },
          {
            path: 'new',
            name: 'admin_communication_template_categories_new',
            component: TemplateCategoryEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_MEMBERSHIP_CATEGORIES],
              permissionsKey: 'create'
            }
          },
          {
            path: ':id',
            name: 'admin_communication_template_categories_edit',
            component: TemplateCategoryEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_MEMBERSHIP_CATEGORIES],
              permissionsKey: 'write'
            }
          }
        ]
      },
      {
        path: 'templates',
        children: [
          {
            path: '',
            name: 'admin_communication_templates',
            component: TemplateIndex,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_COMMUNICATION_TEMPLATES]
            }
          },
          {
            path: 'new',
            name: 'admin_communication_template_new',
            component: TemplateEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_COMMUNICATION_TEMPLATES],
              permissionsKey: 'create'
            }
          },
          {
            path: ':id',
            name: 'admin_communication_template_edit',
            component: TemplateEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_COMMUNICATION_TEMPLATES],
              permissionsKey: 'write'
            }
          },
        ]
      },
      {
        path: 'aro-rules',
        children: [
          {
            path: '',
            name: 'admin_aro_rules',
            component: ARORulesIndex,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: PermissionNames.ADMIN_ARO_RULES
            }
          },
          {
            path: 'new',
            name: 'admin_aro_rules_new',
            component: ARORulesEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: PermissionNames.ADMIN_ARO_RULES,
              permissionsKey: 'create'
            }
          },
          {
            path: ':id',
            name: 'admin_aro_rules_edit',
            component: ARORulesEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: PermissionNames.ADMIN_ARO_RULES,
              permissionsKey: 'write'
            }
          }
        ]
      },
      {
        path: 'application_configurations',
        children: [
          {
            path: 'robocall_reduction',
            name: 'admin_configurations_robocall_reduction',
            component: RobocallReductionsEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_CONFIGURATIONS_ROBOCALL_REDUCTION]
            }
          }
        ]
      },
      {
        path: 'procedure-notes',
        children: [
          {
            path: '',
            name: 'admin_procedure_notes',
            component: ProcedureNotesIndex,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_PROCEDURE_NOTES]
            }
          },
          {
            path: 'new',
            name: 'admin_procedure_notes_new',
            component: ProcedureNotesEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_PROCEDURE_NOTES],
              permissionsKey: 'create'
            }
          },
          {
            path: ':id',
            name: 'admin_procedure_notes_edit',
            component: ProcedureNotesEdit,
            beforeEnter: [ setAdminBreadCrumb ],
            meta: {
              permissions: [PermissionNames.ADMIN_PROCEDURE_NOTES],
              permissionsKey: 'write'
            }
          }
        ]
      },
      {
        path: 'troubleshoot/donors', // This cannot be nested as it only picks up named routes from one level up.
        name: 'admin_troubleshoot_donors',
        component: TroubleshootDonors,
        beforeEnter: [ setAdminBreadCrumb ],
        meta: {
          permissions: [PermissionNames.TROUBLESHOOT_DONORS]
        }
      },
      ...adminIntegrationRoutes
    ]
  },
]