import ManageOnCall from "@/src/components/user/ManageOnCall.vue";
import UserArea from "@/src/components/user/UserArea.vue";
import ManageProfile from "@/src/components/user/ManageProfile.vue";

export const userManagementRoutes = [
  {
    path: 'user',
    component: UserArea,
    children: [
      {
        path: 'manage/on_call',
        name: 'user_manage_on_call',
        component: ManageOnCall,
        meta: {
          permissions: ['internal_user_manage_on_call']
        }
      },
      {
        path: 'manage/profile',
        name: 'user_manage_profile',
        component: ManageProfile,
        meta: {
          permissions: ['internal_user_manage_profile']
        }
      }
    ]
   
  }
]