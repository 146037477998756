import { createWebHistory, createRouter } from "vue-router";
import CoordinatorIframe from "@/src/CoordinatorIframe.vue"
import {useAppCoreStore} from "@/src/stores/app-core";
import Dashboard from "@/src/Dashboard.vue";

// routes 
import {adminRoutes} from "@/src/routes/admin-routes";
import {externalRoutes} from "@/src/routes/external-routes";
import {internalRoutes} from "@/src/routes/internal-routes";
import {userManagementRoutes} from "@/src/routes/user/management-routes";
import { reportRoutes } from "./routes/report-routes";
export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/coordinator_iframe',
      name: 'coordinator-iframe',
      component: CoordinatorIframe
    },
    {
      path: '/ui',
      name: 'app-root',
      redirect: () => {
        return { path: '/ui/dashboard' };
      },
      children: [
        {
          path: 'dashboard',
          component: Dashboard
        },
        ...internalRoutes,
        ...adminRoutes,
        ...reportRoutes,
        ...externalRoutes,
        ...userManagementRoutes
      ]
    },
  ]
});

router.beforeEach((_to, _from) => {
  const appStore = useAppCoreStore();
  appStore.setAppBreadcrumbs([]);
})