import OperationalReportsArea from "@/src/components/admin-area/OperationalReportsArea.vue";
import OperationalReportsDashboard from "@/src/components/admin-area/OperationalReportsDashboard.vue";
import ManageReports from '@/src/components/admin-area/reports/ManageReports.vue';
import ReportSet from "@/src/components/admin-area/reports/MonthlyTxCReportSet.vue";
import { useAppCoreStore } from "@/src/stores/app-core";
import { adminIntegrationRoutes } from "./admin/integration-routes";
import AdminOperationalReportSet from "../components/admin-area/reports/AdminOperationalReportSet.vue";
import MonthlyBusinessReportSet from "../components/admin-area/reports/MonthlyBusinessReportSet.vue";
import {PermissionNames} from "@/src/enums";

export function setReportBreadCrumb() {
  const appStore = useAppCoreStore();
  appStore.setAppBreadcrumbs([
    {
      text: 'Operational Reports'
    }
  ]);
}

export const reportRoutes = [
  {
    path: 'report',
    component: OperationalReportsArea,
    beforeEnter: [ setReportBreadCrumb ],
    children: [
      {
        path: '',
        name: 'report_root',
        component: OperationalReportsDashboard,
        beforeEnter: [ setReportBreadCrumb ],
      },   
      {
        path: 'reports',
        children: [
          {
            path: 'qa-report-upload',
            name: 'admin_ops_reports_qa_report',
            component: ManageReports,
            beforeEnter: [ setReportBreadCrumb ],
            meta: {
              permissions: [PermissionNames.OPERATIONAL_REPORTS_UPLOAD_QA]
            }
          },
          {
            path: 'monthly-txc-reports',
            name: 'admin_ops_reports_monthly_txc_reports',
            component: ReportSet,
            beforeEnter: [ setReportBreadCrumb ],
            meta: {
              permissions: [PermissionNames.OPERATIONAL_REPORTS_MONTHLY_TXC_REPORTING]
            }
          },
          {
            path: 'admin-operational-reports',
            name: 'operational_reports_admin_reports',
            component: AdminOperationalReportSet,
            beforeEnter: [ setReportBreadCrumb ],
          },
          {
            path: 'monthly-business-reports',
            name: 'operational_reports_monthly_business_reports',
            component: MonthlyBusinessReportSet,
            beforeEnter: [ setReportBreadCrumb ],
          }
        ]
      }
    ]
  },
]