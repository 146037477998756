<template>
  <textarea id="donor_details" v-model="donor_details" spellcheck="false"
    name="donor_details" class="col-xs-12"
    rows="50" cols="100" style="width:100%;" />
</template>

<script lang="js">
import { format, utcToZonedTime } from "date-fns-tz";

export default {
  props: {
    details: { default: null, type: Object },
    recipient_details: { default: null, type: Object },
    match: { default: null, type: Object }
  },
  data() {
    return {
      donor_details: {},
      organ_group: null,
      date_format: 'MM/dd/yyyy',
      date_time_format: 'MM/dd/yyyy HH:mm',
      time_format: 'HH:mm',
      lung_tests: ['Bronchoscopy', 'CT/MRI', 'Chest x-ray'],
      heart_tests: ['EKGs', 'Cardiac catheterization', 'CT/MRI', 'Echocardiograms', 'Angiography'],
      risk_key: ['AccordingtotheOPTNpolicyineffectonthedateofreferral,doesthedonorhaveriskfactorsforblood-bornediseasetransmission', 'AccordingtotheOPTNpolicyineffectonthedateofreferraldoesthedonorhaveriskfactorsforbloodbornediseasetransmission'],
            info_keys: {blood_borne_30_days: 'Donormeetsthefollowingcriteriawithin30dayspriortoorganprocurement'},
            blood_borne_risk_factor_keys: ['Unknownmedicalorsocialhistory', 'ChildborntoamotherwithHIVHBVorHCVinfection', 'ChildbreastfedbyamotherwithHIVinfection', 'Incarcerationconfinementinjailprisonorjuvenilecorrectionfacilityfor72ormoreconsecutivehours', 'Sexwithapersonwhoinjecteddrugsfornonmedicalreasons', 'Druginjectionfornonmedicalreasons', 'Sexwithapersonwhohadsexinexchangeformoneyordrugs', 'Sexinexchangeformoneyordrugs', 'Manwhohashadsexwithanotherman', 'SexieanymethodofsexualcontactincludingvaginalanalandoralwithapersonknownorsuspectedtohaveHIVHBVorHCVinfection']
    }
  },
  watch: {
    details() {
      this.populateData();
    },
    recipient_details() {
      this.populateData();
    },
    match() {
      this.populateData();
    }
  },

  mounted() {
    this.populateData();
  },

  methods: {
    populateData() {
      if(!this.recipient_details || !this.details || !this.match) {
        return;
      }
      let details = '';
      const _vm = this
      _vm.donor_details = null;
      const donor = this.details.donor;
      let time_zone = '';
      const organ = this.recipient_details.organs.find(organ => {
        return this.match.organ.name.toString() === organ.name.toString();
      });
      _vm.organ_group = this.recipient_details.organ_groups.find(group => {
        return group.id === organ.organ_group_id ? group.name : '';
      });

      details += `
=== Donor Summary =====
Unos ID: ${donor.code || ''}
Match ID: ${this.match.code || ''}`;

      if (donor.provider_informations.length !== 0) {
        time_zone = donor.provider_informations && donor.provider_informations[0] ? donor.provider_informations[0].time_zone : null;
        details += this.format_provider_information(donor.provider_informations[0]);
        details += this.format_donor_information(donor, time_zone);
      }
      if (donor.cause_and_times.length !== 0) {
        const cause_and_time = donor.cause_and_times[0];

        details += this.format_cause_and_times(cause_and_time, time_zone);
        const cardiac_arrest = cause_and_time.cardiac_arrest ? `Yes` : 'No';
        const cardiac_arrest_with_downtime = cause_and_time.cardiac_arrest_down_time ? `${cardiac_arrest} / ${cause_and_time.cardiac_arrest_down_time} minutes` : cardiac_arrest;
        details += `
Cardiac arrest: ${cardiac_arrest_with_downtime || ''}, CPR Administered: ${cause_and_time.cpr_administered ? 'Yes' : 'No'}`;
        }

        details += `\n`;

        if(donor.highlights) {
            details += `
=== Donor Highlights =====
${donor.highlights || ''}
            `;
        }

      if (donor.admission_course_comments) {
        details += `
=== Admission Course =====
Comments:`;
        details += "\n" + `${donor.admission_course_comments || ''}
            `;
      }

      if (donor.donor_medical_histories.length !== 0) {
        details += `
=== PMHx =====`;

            let normal_pmhx = donor.donor_medical_histories.filter(history => {
                return !this.risk_key.includes(history.medical_condition.code) 
                    && !this.blood_borne_risk_factor_keys.includes(history.medical_condition.code)
                    && !this.info_keys.blood_borne_30_days.includes(history.medical_condition.code)
                    && (history.info ? (history.info.trim() !== 'N/A') : true);
            });
            let blood_borne_pmhx = donor.donor_medical_histories.filter(history => {
                return this.blood_borne_risk_factor_keys.includes(history.medical_condition.code)
                    && (history.info ? (history.info.trim() !== 'N/A') : true)
                ;
            });
            let known_risk = donor.donor_medical_histories.find(history => {
                return this.risk_key.includes(history.medical_condition.code);
            }) ? 'YES' : 'None';

            if(normal_pmhx.length > 0) {
                details += `${this.formatPMHx(normal_pmhx)}`;
            }

            details += `
Known Risk: ${known_risk} ${ blood_borne_pmhx.length > 0 
                         && donor.donor_medical_histories.find(history => {return this.info_keys.blood_borne_30_days.includes(history.medical_condition.code);})
            ? '(meets criteria within 30 days)' : ''}`;

            if(blood_borne_pmhx.length > 0) {
                details += `${this.formatPMHx(blood_borne_pmhx)}`;
            }
            details += `
            `
        }

        if(donor.medical_and_social_history_comments) {
        details += `
=== Medical & social history =====
Comments:`;
        details += "\n" + `${donor.medical_and_social_history_comments}
        `;
        }
        
        if (donor.labs_most_recent || donor.labs_peak_values || donor.labs_on_admit) {
            details += this.format_labs(donor.labs_most_recent, donor.labs_peak_values, donor.labs_on_admit)
        }
        if(donor.donor_infectious_diseases.length != 0) {
            details += this.format_infectious_diseases(donor.donor_infectious_diseases);
        }
        if(donor.ranked_test_and_diagnoses.length != 0)  {
            details += this.format_test_and_diagnoses(donor.ranked_test_and_diagnoses);
        }
        if(donor.recent_ventilator_setting_with_max_fio2) {
            details += this.format_ventilator_settings(donor.recent_ventilator_setting_with_max_fio2,donor.ventilator_settings)
        }
        // if(donor.organ_specific_details.length > 0) {
        //     details += this.format_organ_specific_details(donor.organ_specific_details[0],donor.recent_right_kidney_pump_value[0], donor.recent_left_kidney_pump_value[0])
        // }
        // if(donor.recent_donor_medications.length > 0) {
        //     details += this.format_medication(donor.recent_donor_medications[0])
        // }
        if(this.recipient_details) {
            details += this.format_recipients(this.recipient_details.recipients)
        }

        _vm.donor_details = details;

    },

    format_donor_information(donor,time_zone) {
        const admit_date_time = donor.admit_date_time ? this.formatDateTime(donor.admit_date_time) : '';
        const or_datetime = donor.or_datetime ? this.formatDateTime(donor.or_datetime) : '';
        const height_in_inch = donor.height ? this.convert_cm_to_inch(donor.height) : '';
        const weight_in_lbs = donor.weight ? this.convert_kg_to_lbs(donor.weight) : '';
        const cause_and_times = donor?.cause_and_times ? donor?.cause_and_times[0]?.warm_ischemic_time : 'N/A';
        let details = ``;
        details += `
Age: ${donor.age || ''} Years H: ${donor.height || ''}cm/${height_in_inch}in W: ${donor.weight || ''}kg/${weight_in_lbs}lbs BMI: ${donor.bmi || ''} kg/m2
Birth Sex: ${donor.birthsex ? donor.birthsex : donor.gender}
KDPI: ${donor.kdpi || ''}
Admit Date: ${admit_date_time}
Blood Type: ${donor.blood_type || ''}
Donor Meets DCD Criteria: ${donor.dcd_criteria_meets ? 'Yes (DCD)' : 'No (BD)'}
Warm Ischemic time: ${cause_and_times ? cause_and_times : 'N/A'}`;
        if(or_datetime) {
            details += `
Donor OR Time: ${or_datetime} ${time_zone}`;
        }
        return details;
    },

    format_provider_information(provider_information) {
        let details = `
Donor Location: ${provider_information.donor_hospital || ''}`;
        if(provider_information.travel_time) {
           details += `
Travel time: ${provider_information.travel_time}`;
        }
        return details;
    },

    format_cause_and_times(cause_and_time,time_zone) {
        const death_datetime = cause_and_time.death_datetime ? this.formatDateTime(cause_and_time.death_datetime) : '';
        const cross_clamp_datetime = cause_and_time.cross_clamp_datetime ? this.formatDateTime(cause_and_time.cross_clamp_datetime) : '';

        let details = ``;
        if(death_datetime) {
            details += `
Pronounced: ${death_datetime}`;
        }
        details += `
COD: ${cause_and_time.cause_of_death}, ${cause_and_time.mechanism_of_injury}`;
        if(cross_clamp_datetime) {
            details += `
Cross-clamp Date: ${cross_clamp_datetime} ${time_zone}`;
        }
        return details;
    },

    format_infectious_diseases(donor_infectious_diseases) {
        let details = `
        `;
        let donor_infectious_disease_names = [];
        let other_diseases = [];
        donor_infectious_diseases.map((donor_disease)=> {
           if(donor_disease.result == 'Positive') {
            let disease = donor_disease.infectious_disease
            disease.code == 'Other' ? other_diseases.push(donor_disease.test_method) : donor_infectious_disease_names.push(disease.code);
           }
        });
        if(donor_infectious_disease_names.length > 0) {
            details += `
Positive Seros: ${donor_infectious_disease_names.join(', ')}
            `;
        }

        if(other_diseases.length > 0) {
            details += `
Was COVID-19 (SARS-CoV-2) testing performed on the donor?: Yes
`;
        }
        return details;
    },

    format_test_and_diagnoses(test_and_diagnoses) {
        const most_recent_tests = test_and_diagnoses.filter(test => {
            return test.tests_rank == 1;
        });

        let test_details = ``;

        most_recent_tests.map(test => {
            const test_datetime = test.datetime ? this.formatDateTime(test.datetime) : '';
            if(test.test_type == 'Other') {
          test_details += `
++++ ${test.test_type || ''}, specify: ${test.other_test_type || ''} ++++++++++++
Test Date: ${test_datetime || ''}

${test.comments}
    `;
            }
            else {
                // When new organ groups are added, they need to be added here too.
                switch(this.organ_group.name) {
                    case "Liver":
                    case "Kidney":
                    case "Pancreas":
                    case "Head/Neck":
                    case "Kidney-Pancreas":
                        if(test.test_type == 'CT/MRI') {
                            test_details += `${this.format_tests(test)}`;
                        }
                    break;
                    case "Lung":
                        if(this.lung_tests.includes(test.test_type)) {
                            test_details += `${this.format_tests(test)}`;
                        }
                    break;
                    case "Heart":
                        if(this.heart_tests.includes(test.test_type)) {
                            test_details += `${this.format_tests(test)}`;
                        }
                    break;
                    case "Heart/Lung":
                        if(this.heart_tests.includes(test.test_type)) {
                            test_details += `${this.format_tests(test)}`;
                        }
                        if(this.lung_tests.includes(test.test_type)) {
                            test_details += `${this.format_tests(test)}`;
                        }
                    break; 
                }
            }
        });
      
        if(test_details) {
            return `
=== Tests & Diagnoses =====
        ${test_details}
        `;
        }
        else {
          return ''
        }
    },

    format_tests(test) {
        const test_datetime = test.datetime ? this.formatDateTime(test.datetime) : '';

        let details = ``;
        details += `
++++ ${test.test_type} ++++++++++++
Test Date: ${test_datetime || ''}

${test.comments}
    `;
        return details;
    },

    format_labs(labs_most_recent, labs_peak_values, labs_admit_values) {
        let details = `
=== Labs Results =====`;
        let time = '';
        let labs = [];
        switch(this.organ_group.name) {

            case "Liver":
                labs = [
                    { name: "Na(mEqL)", key: "na", lab_type: "lab_panel" },
                    { name: "Glucose(mg/dL)", key: "glucose", lab_type: "lab_panel"  },
                    { name: "Creatinine(mg/dL)", key: "serum_creatinine", lab_type: "lab_panel" },
                    { name: "Total Bilirubin(mg/dL)", key: "total_bilirubin", lab_type: "lab_panel" },
                    { name: "Direct Bilirubin(mg/dL)", key: "direct_bilirubin", lab_type: "lab_panel" },
                    { name: "Indirect Bilirubin(mg/dL)", key: "indirect_bilirubin", lab_type: "lab_panel" },
                    { name: "SGOT(AST) (u/L)", key: "sgot_ast", lab_type: "lab_panel" },
                    { name: "SGPT(ALT) (u/L)", key: "sgpt_alt", lab_type: "lab_panel" },
                    { name: "Alkaline phosphatase(u/L)", key: "alkaline_phosphatase", lab_type: "lab_panel" },
                    { name: "GGT(u/L)", key: "ggt", lab_type: "lab_panel" },
                    { name: "Albumin(g/dL)", key: "albumin", lab_type: "lab_panel" },
                    { name: "Total Protein(g/dL)", key: "total_protein", lab_type: "lab_panel" },
                    { name: "Prothrombin PT", key: "prothrombin_pt", lab_type: "lab_panel" },
                    { name: "INR", key: "inr", lab_type: "lab_panel" },
                    { name: "PTT", key: "ptt", lab_type: "lab_panel" },
                    { name: "HbA1c", key: "hba1c", lab_type: "lab_extra_value" },
                ];
                details += this.generate_lab_string(labs, labs_most_recent, labs_peak_values, labs_admit_values);
                return details;
            break;

            case "Kidney":
                labs = [
                    { name: "BUN(mg/dL)", key: "bun", lab_type: "lab_panel" },
                    { name: "Glucose(mg/dL)", key: "glucose", lab_type: "lab_panel" },
                    { name: "Creatinine(mg/dL)", key: "serum_creatinine", lab_type: "lab_panel" },
                    { name: "Prothrombin PT", key: "prothrombin_pt", lab_type: "lab_panel" },
                    { name: "INR", key: "inr", lab_type: "lab_panel" },
                    { name: "PTT", key: "ptt", lab_type: "lab_panel" },
                    { name: "HbA1c", key: "hba1c", lab_type: "lab_extra_value" },
                    
                ];
                details += this.generate_lab_string(labs, labs_most_recent, labs_peak_values, labs_admit_values);
                return details;
            break;

            case "Pancreas":
                labs = [
                    { name: "Na(mEqL)", key: "na", lab_type: "lab_panel" },
                    { name: "Glucose(mg/dL)", key: "glucose", lab_type: "lab_panel"  },
                    { name: "Serum Amylase (u/L)", key: "serum_amylase", lab_type: "lab_panel"  },
                    { name: "Serum Lipase (u/L)", key: "serum_lipase", lab_type: "lab_panel"  },
                    { name: "Serum Lipase Upper Normal Limit (u/L)", key: "serum_lipase_upper_normal_limit", lab_type: "lab_panel"  },
                    { name: "Prothrombin PT", key: "prothrombin_pt", lab_type: "lab_panel"  },
                    { name: "INR", key: "inr", lab_type: "lab_panel" },
                    { name: "PTT", key: "ptt", lab_type: "lab_panel" },
                    { name: "HbA1c", key: "hba1c", lab_type: "lab_extra_value" },
                ];
                details += this.generate_lab_string(labs, labs_most_recent, labs_peak_values, labs_admit_values);
                return details;
            break;

            case "Kidney-Pancreas":
                labs = [
                    { name: "Na(mEqL)", key: "na", lab_type: "lab_panel" },
                    { name: "BUN(mg/dL)", key: "bun", lab_type: "lab_panel" },
                    { name: "Glucose(mg/dL)", key: "glucose", lab_type: "lab_panel"  },
                    { name: "Creatinine(mg/dL)", key: "serum_creatinine", lab_type: "lab_panel" },
                    { name: "Serum Amylase (u/L)", key: "serum_amylase", lab_type: "lab_panel"  },
                    { name: "Serum Lipase (u/L)", key: "serum_lipase", lab_type: "lab_panel"  },
                    { name: "Serum Lipase Upper Normal Limit (u/L)", key: "serum_lipase_upper_normal_limit", lab_type: "lab_panel"  },
                    { name: "Prothrombin PT", key: "prothrombin_pt", lab_type: "lab_panel"  },
                    { name: "INR", key: "inr", lab_type: "lab_panel" },
                    { name: "PTT", key: "ptt", lab_type: "lab_panel" },
                    { name: "HbA1c", key: "hba1c", lab_type: "lab_extra_value" },
                ];
                details += this.generate_lab_string(labs, labs_most_recent, labs_peak_values, labs_admit_values);
                return details;
            break;

            case "Intestine":
                labs = [ 
                    { name: "Na(mEqL)", key: "na", lab_type: "lab_panel" },
                    { name: "Glucose(mg/dL)", key: "glucose", lab_type: "lab_panel"  },
                    { name: "Creatinine(mg/dL)", key: "serum_creatinine", lab_type: "lab_panel" },
                    { name: "Total Bilirubin(mg/dL)", key: "total_bilirubin", lab_type: "lab_panel" },
                    { name: "Direct Bilirubin(mg/dL)", key: "direct_bilirubin", lab_type: "lab_panel" },
                    { name: "Indirect Bilirubin(mg/dL)", key: "indirect_bilirubin", lab_type: "lab_panel" },
                    { name: "SGOT(AST) (u/L)", key: "sgot_ast", lab_type: "lab_panel" },
                    { name: "SGPT(ALT) (u/L)", key: "sgpt_alt", lab_type: "lab_panel" },
                    { name: "Alkaline phosphatase(u/L)", key: "alkaline_phosphatase", lab_type: "lab_panel" },
                    { name: "Prothrombin PT", key: "prothrombin_pt", lab_type: "lab_panel" },
                    { name: "INR", key: "inr", lab_type: "lab_panel" },
                    { name: "PTT", key: "ptt", lab_type: "lab_panel" },
                    { name: "Serum Amylase (u/L)", key: "serum_amylase", lab_type: "lab_panel"  },
                    { name: "Serum Lipase (u/L)", key: "serum_lipase", lab_type: "lab_panel"  },
                    { name: "HbA1c", key: "hba1c", lab_type: "lab_extra_value" },
                ];
                details += this.generate_lab_string(labs, labs_most_recent, labs_peak_values, labs_admit_values);
                return details;
            break;

            case "Heart":
            case "Heart/Lung":
                labs = [
                    { name: "Troponin I(ng/mL)", key: "troponin_i", lab_type: "lab_value" },
                    { name: "Troponin T(ng/mL)", key: "troponin_t", lab_type: "lab_value" },
                    { name: "CPK(u/L)", key: "cpk", lab_type: "lab_value" },
                    { name: "CKMB(ng/mL)", key: "ckmb", lab_type: "lab_value" }
                ];
                details += this.generate_lab_string(labs, labs_most_recent, labs_peak_values, labs_admit_values);
                return details;
            break;

        }

        return '';
    },

    format_ventilator_settings(max_fio2_settings,ventilator_settings) {
        let details = '';
         let time = '';
         
            switch(this.organ_group.name) {
                case "Lung":
                case "Pancreas":
                case "Heart/Lung":
                case "Kidney-Pancreas":
                details += `
=== ABGs =====`;
                if(ventilator_settings.length > 0 && max_fio2_settings.length == 0) {
                  details += `
  For all ABGs, FiO2 was less than 100%
`;
                  break;
                } else {}
                max_fio2_settings.forEach(setting => {
                    time = this.formatTime(setting.time);
            details += `
Date : ${setting.date}, Time : ${time}
pH   : ${setting.ph_abg || ''}
PaCO2: ${setting.paco2 || ''}
PaO2 : ${setting.pao2 || ''}
HCO3 : ${setting.hco3 || ''}
SaO2 : ${setting.sao2 || ''}
FiO2 (%) : ${setting.fio2 || ''}
RR : ${setting.rr_result || ''}
Vt (cc) : ${setting.vt_cc || ''}
PEEP (cmH20) : ${setting.peep || ''}
            `;
              });
              break;
            }

        return details;
    },

    format_organ_specific_details(details, right_pump_values, left_pump_values) {
        let organ_details = ``;
        switch(this.selected_row.organ) {
            case "Lung":
            organ_details += `
            Lung measurements:
                Length of the left lung:[${details.length_of_the_left_lung}],
                Length of the right lung:[${details.length_of_the_right_lung}],
                Aortic knob width:[${details.aortic_knob_width}],
                Diaphragm width:[${details.diaphragm_width}],
                Chest cird/landmark:[${details.chest_circl_landmark}],
                Dist RCPA to LCPA:[${details.dist_rcpa_to_lcpa}],
                Chest Xray:[${details.chest_xray}]
                Peak inspiratory pressure:
            `;
            return organ_details;
            break;

            case "Kidney":
            organ_details += `
            Right kidney Details:
                Right kidney biopsy: [${details.right_kidney_biopsy}]
                % Glomeruloscerosis: [${details.right_kidney_glomeruloscerosis}]
                Biopsy Type: [${details.right_kidney_biopsy_type}]
                Glomeruli Count: [${details.right_kidney_glomeruli_count}]
                Right Pump: [${details.right_kidney_pump_device}],
                Flow: [${right_pump_values.flow_ccmin}]
                Pressure: [${right_pump_values.pressure}],
                Resistance: [${right_pump_values.resistance}]
            Left kidney Details:
                Left kidney biopsy: [${details.left_kidney_biopsy}]
                % Glomeruloscerosis: [${details.left_kidney_glomeruloscerosis}]
                Biopsy Type: [${details.left_kidney_biopsy_type}]
                Glomeruli Count: [${details.left_kidney_glomeruli_count}]
                Left Pump: [${details.left_kidney_pump_device}],
                Flow: [${left_pump_values.flow_ccmin}]
                Pressure: [${left_pump_values.pressure}],
                Resistance: [${left_pump_values.resistance}]
            `;
            return organ_details;
            break;
        }
    },

    format_medication(medication) {
        let details = `
            Medications & Fluids: `;
            details += `
                Inotropic medication : ${medication.inotropic_medication}
                Begin date & time : ${medication.begin_datetime}
                End date & time : ${medication.end_datetime || ''}
                Value : ${medication.value}
                Units : ${medication.units}
                Duration(hours) : ${medication.duration_hours || 'ONGOING'}
            `;
        return details;
    },

    format_recipients(recipients) {
        let details = ``;
        let counter = 1;

        recipients.map(recipient => {
             const offerStatus = recipient.offer_status.includes('Organ Placed') ? '(Organ Placed)' : '';
            details += `
=== Recipient ${counter}${offerStatus} =====
Sequence: ${recipient.seq || ''}
Rank: ${recipient.rank || 'N/A'}
Last Name: ${recipient.recipient_last_name || ''}
Age: ${recipient.age || ''} years
Blood Type: ${recipient.blood_type || ''}`;
            switch(this.organ_group.name) {

            case "Kidney":
              const recipientHeight = recipient.height ? `[${recipient.height}cm/${this.convert_cm_to_inch(recipient.height)}inch]` : '';
              const recipientWeight = recipient.weight ? `[${recipient.weight}kg/${this.convert_kg_to_lbs(recipient.weight)}lbs]` : '';
              const recipientBMI = recipient.bmi ? `[${recipient.bmi}]` : '';
            details += `
CPRA: ${recipient.cpra || ''}
Score: ${recipient.score || ''}
Mismatch A:  ${recipient.mismatch_a || ''}
Mismatch B:  ${recipient.mismatch_b || ''}
Mismatch DR: ${recipient.mismatch_dr || ''}
Height: ${recipientHeight}, Weight: ${recipientWeight}, BMI: ${recipientBMI}
# Prev Ki Tx: # ${recipient.prev_kidney_transplants || 0}`;
          if(recipient.dialysis_time || '') {
            details += `
Dialysis Time: ${recipient.dialysis_time || ''}`;
           }
            break;

            case "Kidney-Pancreas":
            case "Pancreas":
            case "Pancreas Islet":

            if(recipient.cpra) {
                details += `
CPRA: ${recipient.cpra}`;
            }
            if(recipient.score) {
                details += `
Score: ${recipient.score}`;
            }
            if(recipient.mismatch_a) {
                details += `
Mismatch A: ${recipient.mismatch_a}`;
            }
            if(recipient.mismatch_a) {
                details += `
Mismatch B: ${recipient.mismatch_b}`;
            }
            if(recipient.mismatch_a) {
                details += `
Mismatch DR: ${recipient.mismatch_dr}`;
            }
            if(recipient.dialysis_time) {
            details += `
Dialysis Time: ${recipient.dialysis_time}`;
           }
            details += `
# Prev Ki Tx: # ${recipient.prev_kidney_transplants || 0}
Organ(s) Being Offerred: ${recipient.organs_being_offered || 'None'}`;
            break;

            case "Lung":
            let preference = ``;
            preference += recipient.preference_ll ? ` LL: ${recipient.preference_ll}` : ``;
            preference += recipient.preference_rl ? ` RL: ${recipient.preference_rl}` : ``;
            preference += recipient.preference_bl ? ` BL: ${recipient.preference_bl}` : ``;

            details += `
WL Organ: ${recipient.wait_listed_organ || 'None'}
X: ${recipient.x_value}`;
            if(preference){
                details += `
Preference: { ${preference} }
                `;
            }
            if(recipient.las){
            details += `
LAS: ${recipient.las}`;
            }
            break;

            case "Heart":
            details += `
WL Organ: ${recipient.wait_listed_organ || 'None'}
UA: ${recipient.ua_value || ''}`;
            if(recipient.status) {
                const status = recipient.status?.split(',')[1].match(/[.\d]+/)[0]
                details += `
Status : ${status}`;
            }
            break;

            case "Intestine":

            details += `
UA: ${recipient.ua_value || ''}
X: ${recipient.x_value || ''}`;
            break;

            case "Liver":
                details += `
height[${recipient.height}cm/${this.convert_cm_to_inch(recipient.height)}inch], weight [${recipient.weight}kg/${this.convert_kg_to_lbs(recipient.weight)}lbs], BMI [${recipient.bmi}]
# Prev LI Tx: # ${recipient.prev_liver_transplants || 0}
MELD: ${recipient.score || ''}`;
            if(recipient.liver_segment) {
                details += `
LISEQ: ${recipient.liver_segment || ''}`;
                }
            break;

            case "Bowel":
           if(recipient.meld_peld_score) {
            details += `
MELD/PELD: ${recipient.meld_peld_score || ''}`;
           }
            break;

            }

            counter += 1;
            details += `
Other Organs: ${recipient.other_organs || 'None'}
            `;
        });
        return details;
    },
    convert_cm_to_inch(height){
        if(height) {
            return (height * 0.39370).toFixed(1);
        }
        return '';
    },

    convert_kg_to_lbs(weight){
        if(weight) {
            return (weight * 2.2046).toFixed(1);
        }
        return '';
    },

    generate_concatenated_string(data) {
        let conc_string = ``;
        data.forEach((key, value) => {
            if(key['value'] != null) {
                conc_string += `
${key['key']} : ${key['value']}`;
            }
        });
        return conc_string;
    },

    generate_lab_string(data, currentLabs, peakLabs, admitLabs) {
        let conc_string = ``;
        const values_type_order = ['current', 'peak', 'admit']
        data.forEach(lab => {
            conc_string += `
${lab['name']} - `;
            values_type_order.forEach((type) => {
                if(type == 'current') {
                    const lab_type = lab['lab_type'];
                    const current = currentLabs[`current_${lab_type}`];
                    if (!current[lab['key']]) { return; }
                    if (lab_type == "lab_extra_value" && lab['name'] == "HbA1c") {
                        conc_string += `${current[lab['key']][lab['key']]} (${this.formatDateTime(current[lab['key']]['hba1c_datetime'])})`;
                        return; 
                    }

                    conc_string += `C:${current[lab['key']][lab['key']]} `;
                    if (lab_type != "lab_extra_value") { conc_string +=  `(${this.formatDate(current[lab['key']]['date']) + ' ' + this.formatTime(current[lab['key']]['time'])}) `; }
                    else { conc_string +=  `(${this.formatDateTime(current[lab['key']]['hba1c_datetime'])}) `; }
                }
                if(type == 'peak') {
                    const lab_type = lab['lab_type'];
                    const peak = peakLabs[`peak_${lab_type}`];
                    if (!peak[lab['key']]) { return; }
                    if (peak[lab['key']].length < 1) { return; }
                    if (lab_type == "lab_extra_value" && lab['name'] == "HbA1c") { return; }

                    conc_string += `P:${peak[lab['key']][0][lab['key']]} (`;
                    if (peak[lab['key']].length > 1) { conc_string += `multiple, most recent: ` }  
                    if (lab_type != "lab_extra_value") { conc_string +=  `${this.formatDate(peak[lab['key']][0]['date']) + ' ' + this.formatTime(peak[lab['key']][0]['time'])}) `; }
                    else { conc_string +=  `${this.formatDateTime(peak[lab['key']][0]['hba1c_datetime'])}) `; }
                }
                if(type == 'admit') {
                    const lab_type = lab['lab_type'];
                    const admit = admitLabs[`admit_${lab_type}`];
                    if (!admit[lab['key']]) { return; }
                    if (lab_type == "lab_extra_value" && lab['name'] == "HbA1c") { return; }

                    conc_string += `A:${admit[lab['key']][lab['key']]} `;
                    if (lab_type != "lab_extra_value") { conc_string +=  `(${this.formatDate(admit[lab['key']]['date']) + ' ' + this.formatTime(admit[lab['key']]['time'])}) `; }
                    else { conc_string +=  `(${this.formatDateTime(admit[lab['key']]['hba1c_datetime'])}) `; }
                }
            });
        });

        return conc_string;
    },

    formatPMHx(donor_medical_histories) {
        let details = ``;
        donor_medical_histories.map(history =>{
            if(history.info && (history.info.trim() == 'UNKNOWN') ) {
                details += `
- ${history.medical_condition.name || '<unknown condition name>'}: ${history.info.trim()}`;
            } else if (history.info && (history.info.trim() == 'N/A') ) {
                details += ``;
            } 
            else {
                details += `
- ${history.medical_condition.name || '<unknown condition name>'}: ${history.info ? `YES, ${history.info.trim()}` : "YES"}`;             
            }
        });
        
        return details;
    },            

    formatDateTime(datetime) {
        const dt = new Date(datetime);
        return format(utcToZonedTime(dt, "UTC"), this.date_time_format, { timeZone: "UTC"});
    },
    formatTime(datetime) {
        const dt = new Date(datetime);
        return format(utcToZonedTime(dt, "UTC"), this.time_format, { timeZone: "UTC"});
    },
    formatDate(datetime) {
        const dt = new Date(datetime);
        return format(utcToZonedTime(dt, "UTC"), this.date_format, { timeZone: "UTC"});
    }
  }
}
</script>
